import React from 'react'
import {Nav, NavDropdown} from "react-bootstrap";
import {ROUTE_HOME_SERVICES, ROUTE_SERVICES} from "../../../routes/constants";
import {rootCategories} from "../../../utils/constants/category";
import {translate} from "../../../utils/i18n";
import {getDownloadAppLink} from "../../../utils/constants/app";
import {Icon} from "@iconify/react/dist/iconify";
import {colors} from "../../../utils/style/style";

export const PageLinkNav = (props) => {
    return <>
        <Nav.Link href={getDownloadAppLink()}>
            <Icon icon="eva:download-fill" width={24} />
            Aplicatie
        </Nav.Link>

        {/*<NavDropdown title="Servicii" >*/}
        {/*    <NavDropdown.Item href={ROUTE_SERVICES} className="dropdown-menu-inner">Toate serviciile</NavDropdown.Item>*/}
        {/*    {*/}
        {/*        rootCategories.map((item, index) => {*/}
        {/*            return <NavDropdown.Item key={index + 1} href={`${ROUTE_SERVICES}?categoryId=${item.id}`}*/}
        {/*                                     className="dropdown-menu-inner">*/}
        {/*                {translate('category.' + item.code)}*/}
        {/*            </NavDropdown.Item>*/}
        {/*        })*/}
        {/*    }*/}
        {/*</NavDropdown>*/}
        <Nav.Link href={`${ROUTE_HOME_SERVICES}`}>Zumzi Plus</Nav.Link>
        <Nav.Link href="/servicii">Alte Servicii</Nav.Link>
        <Nav.Link href="/intrebari">Intrebari</Nav.Link>
        <Nav.Link href="/contact">Contact</Nav.Link>

    </>
}

export default PageLinkNav
