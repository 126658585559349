import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {Icon} from "@iconify/react/dist/iconify";
import {Link} from "react-router-dom";

import "../../assets/css/guest/footer.scss"
import {colors} from "../../utils/style/style";
import zumzi_logo from "../../assets/images/logo_zuzmi_alb_text.png"
import {ROUTE_PARTNER, ROUTE_PARTNER_APP, ROUTE_SERVICE} from "../../routes/constants";
import {PRODUCT_ID_MANAGEMENT_SUBSCRIPTION} from "../../utils/constants/product";

export const Footer = (props) => {
	const color = colors.white

	return (
		<div className="footer">
			<Container>
				<Row>
					<Col lg={4} className="mb-5 text-center-n-lg">
						<h4 className="footer-title">
							Pagini utile
						</h4>
						<Link to="/" className="footer-link">
							Acasa
						</Link>
						<Link to="/servicii" className="footer-link">
							Servicii
						</Link>
						<Link to="/intrebari" className="footer-link">
							Intrebari
						</Link>
						<Link to={`${ROUTE_SERVICE}?productId=${PRODUCT_ID_MANAGEMENT_SUBSCRIPTION}`}
							  className="footer-link">
							Abonament
						</Link>
					</Col>

					<Col lg={4} className="mb-5 text-center-n-lg">
						<h4 className="footer-title">
							Legal
						</h4>
						<Link to="/termeni-si-conditii" className="footer-link">
							Termeni si Conditii
						</Link>
						<Link to="/politica-de-confidentialitate" className="footer-link">
							Politica de Confidentialitate
						</Link>
						<Link to="/politica-de-utilizare-cookie" className="footer-link">
							Politica de Cookie
						</Link>
					</Col>

					<Col lg={4} className="mb-5 text-center-n-lg">
						<h4 className="footer-title">
							Contact
						</h4>
						<a href="#" className="footer-contact-link">
							<Icon icon="ic:round-schedule" color={color} width={20} height={20}/>
							<span>
                            L-V 08:00 - 18:00
                        </span>
						</a>


						<a href="tel:+40723146609" className="footer-contact-link">
							<Icon icon="akar-icons:phone" color={color} width={20} height={20}/>
							<span>
                            +40 723 146 609
                        </span>
						</a>
						<a href="mailto:hello@zumzi.com" className="footer-contact-link">
							<Icon icon="ant-design:mail-outlined" color={color} width={20} height={20}/>
							<span>
                            hello@zumzi.com
                        </span>
						</a>
						<div className="mt-3">
							<a href={ROUTE_PARTNER_APP} className="button-cta-sec p-font-size d-inline-block">
								Devino partener
							</a>
						</div>

					</Col>
				</Row>

				<Row className="mt-5">
					<Col lg={8} className="mb-5 text-center-n-lg">
						<img src={zumzi_logo} alt="logo zumzi alb" width="100"/>
					</Col>
					<Col lg={4} className="footer-socials  text-center-n-lg">
						<div>
							<a href="https://www.facebook.com/zumzi" className="footer-social-link">
								<Icon icon="brandico:facebook" color={color} width={20} height={20}/>
							</a>
							<a href="https://www.instagram.com/zumzi.app" className="footer-social-link">
								<Icon icon="ant-design:instagram-outlined" color={color} width={20} height={20}/>
							</a>
							<a href="https://www.tiktok.com/@zumzi.com" className="footer-social-link">
								<Icon icon="ph:tiktok-logo" color={color} width={20} height={20}/>
							</a>
							<a href="https://www.linkedin.com/company/zumzi/" className="footer-social-link">
								<Icon icon="bi:linkedin" color={color} width={20} height={20}/>
							</a>
						</div>

						<p className="footer-link mt-3">
							@Copyright © 2021 Zumzi
						</p>
					</Col>
				</Row>
			</Container>

		</div>
	)
}

export default Footer