import React from 'react';
import GuestTemplate from "./GuestTemplate";
import Tab from 'react-bootstrap/Tab'
import {Col, Container, Nav, Row} from "react-bootstrap";

import "../../assets/css/guest/faq.scss"
import {Icon} from "@iconify/react/dist/iconify";
import {getItemFromUrl} from "../../utils/interface/queryHandler";
import {ROUTE_CONTACT, ROUTE_SERVICES} from "../../routes/constants";
import {Link} from "react-router-dom";

const texts = {
	order: {
		icon: 'akar-icons:cart',
		title: 'Comanda',
		sections: [
			{
				title: 'Cum fac o comanda?',
				desc: 'Comanda se face prin intermediul aplicației Zumzi sau prin intermediul site-ului. Vom colecta câteva date personale - nume, prenume, număr de telefon și adresă, pentru ca partenerii să ajungă la locație. Plata se va face online, în sistem securizat. După ce vei plasa comanda, vei primi un e-mail de confirmare.'
			},
			{
				title: 'Trebuie să fiu acasă in timpul serviciului?',
				desc: 'Decizia în acest caz este a ta. Dacă, însă, alegi să nu fii acasă, te rugăm să lași indicații precise despre locul unde poți lăsa cheia, pentru ca partenerul nostru să poată intra în locație. Îți garantăm că munca partenerului va fi la fel de eficientă, indiferent ce decizi.'
			},
			{
				title: 'Este necesar să las bacșiș?',
				desc: 'Politica Zumzi este să nu încurajeze această formă de răsplată. Decizia este a fiecărui utilizator.'
			},
			{
				title: 'Ce este inclus într-o sedință?',
				desc: <>
					Serviciile noastre standard includ toate lucrurile necesare pentru ca locuința ta să arate perfect.
					Poți verifica pagina cu
					<Link to={ROUTE_SERVICES}>
						{' '}servicii
					</Link>.
				</>,
			},
			{
				title: 'În urma sedinte un lucru este defect sau lipsește,',
				desc: 'Încrederea ta este una dintre prioritățile noastre. Completează formularul de contact cu o descriere detaliată a problemei și vom reveni la tine în cel mai scurt timp posibil. După investigația noastră este posibil să avem nevoie de acte doveditoare sau poze care atestă că obicetul se află în posesia dumneavoastră. Dacă un obiect lipsește sau a fost defectat, te rugăm să ne contactezi în maxim 72 de ore de la producerea incidentului.',
			},
			{
				title: 'Ce se întâmplă în cazul accidentelor în care se deteriorează bunuri sau lucruri de valoare?',
				desc: 'Partenerii noștri sunt instruiți special cu privire la activitatea lor în cel mai profesionist mod. Cu toate acestea, mici accidente se pot întâmpla. Pentru a ne asigura că bunurile dumneavoastră nu vor fi deteriorate, dispunem și de o asigurare de până la 5000 Euro',
			},
		]
	},

	schedule: {
		icon: 'ic:baseline-schedule',
		title: 'Reprogramare',
		sections: [
			{
				title: 'Care este politica de anulare sau reprogramare?',
				desc: <>
					Știm că planurile se pot schimba rapid, așa că politica Zumzi permite
					anularea/reprogramarea unei comenzi cu cel puțin 48 de ore înainte de ora programată. Pentru
					comenzi care trebuie reprogramate/anulate în timp util vă rugăm să ne contactați prin
					formularul de <Link to={ROUTE_CONTACT}>contact</Link> sau telefonic <a
					href="tel:+40723146609">0723146609</a> de L-V, oricând între 08:00 - 18:00. În cazul în care
					schimbarea se va face cu mai puțin de 48 de ore, ne rezervăm dreptul de a percepe o taxă de
					anulare/reprogramare.
				</>
			}
		]
	},

	payment: {
		icon: 'fluent:payment-16-regular',
		title: 'Plata',
		sections: [
			{
				title: 'Plata recurenta',
				desc: 'Plătești prima lună din abonament, iar apoi ți se vor retrage banii recursiv în fiecare lună.',
			},
			{
				title: 'Unde gasesc factura aferentă comenzii?',
				desc: <>
					Suntem în proces de automatizare a soluției de facturare. Deocamdată poți completa câmpul de
					persoană juridică sau ne poți cere facturare la adresa de mail:
					<a href="mailto:hello@zumzi.com">{' '}hello@zumzi.com</a>
				</>
			},
			{
				title: 'Procedura plată online/ plată recurentă',
				desc: 'La metoda de plată „Online prin card bancar" este necesar să completați un formular cu informatiile despre cardul dumneavoastră în pagina securizată a procesatorului de plăți. Plățile cu carduri de credit/debit emise sub sigla Visa și MasterCard (Visa/Visa Electron și MasterCard/Maestro) se efectuează prin intermediul sistemului „3-D Secure" elaborat de organizațiile care asigură tranzacțiilor online același nivel de securitate ca cele realizate la bancomat sau in mediul fizic, la comerciant. „3-D Secure" asigură în primul rând faptul că nicio informație legată de cardul dumneavoastră nu va fi transferată sau stocată, la niciun moment de timp, pe serverele magazinului sau pe serverele procesatorului de plăți, aceste date fiind direct introduse în sistemele Visa și MasterCard. Important de știut! Pentru plățile prin card bancar nu este perceput niciun comision.'
			}
		]
	},

	partner: {
		icon: 'ant-design:user-switch-outlined',
		title: 'Partener',
		sections: [
			{
				title: 'Cum funcționează platforma Zumzi?',
				desc: <>
					Zumzi este o platformă ce oferă servicii „on-demand" pentru acasă prin intermediul partenerilor ei.
					Utilizatorului i se asignează un partener prin intermediul platformei pentru a realiza serviciul
					ales. În momentul de față platforma oferă servicii la domiciliu doar în București.
				</>
			},
			{
				title: 'Ce se întâmplă dacă partenrul nu a returnat cheile?',
				desc: <>
					Astfel de situații nu au avut loc până acum, dar în cazul în care se întâmplă, te rugăm să
					ne contactezi cât mai curând prin intermediul <Link to={ROUTE_CONTACT}>formularului
					de&nbsp;contact&nbsp;</Link>sau
					telefonic la numarul
					<a href="tel:+40723146609">{' '}0723146609</a> de L-V, oricând între 08:00 - 18:00.
				</>
			},
		]
	},

	receive_order: {
		icon: 'carbon:order-details',
		title: "Preluare comenzi",
		sections: [
			{
				title: "Cum contactez partenrul asignat pentru comanda mea?",
				desc: <>
					Cu ceva timp înainte ca programarea dumneavoastră să înceapă, vă vom
					contacta pentru a vă furniza datele partenerului. Pot fi livrate prin sms sau prin
					e-mail. În cazul în care aveți un cont creat, datele de contact ale partenerului nostru vor
					fi afișate în dashbord-ul de comenzi. Dacă totuși nu puteți să luați legătura cu partenerul
					asignat pentru dumneavoastră, vă sugerăm să contactați echipa de la birou prin intermediul
					formularului de <Link to={ROUTE_CONTACT}>contact</Link> sau prin telefon la <a
					href="tel:+40723146609">0723146609</a> de L-V, oricând între 08:00 - 18:00
				</>
			},
			{
				title: "În ce interval se pot desfașura serviciile?",
				desc: <>
					De regulă, ședințele se desfășoară în intervalul 08:00-19.00. Dacă, însă, îți dorești un alt
					interval, te rugăm să ne contactezi și în măsura în care va fi posibil, vom încerca să te ajutăm.
				</>
			}
		]
	},

	reviews: {
		icon: 'ic:outline-reviews',
		title: "Recenzii",
		sections: [
			{
				title: "Cum pot să am încredere în partenerii platformei?",
				desc: <>
					Încrederea și siguranța sunt esențiale pentru echipa noastră. Partenerii noștri sunt atent
					selectionați printr-un interviu riguros, sunt pregătiți prin traininguri, iar trecutul acestora este
					verificat prin documente legale. În plus, te poți orienta și în funcție de rating-urile celorlalți
					utilizatori care au colaborat deja cu unul dintre parteneri.
				</>
			},
			{
				title: "Cum îmi exprim părerea despre munca/comportamentul partenerului?",
				desc: "Părerea noastră este foarte importantă pentru noi. De aceea, încurajăm toți utilizatorii să ne lase un feedback prin intermediul recenziilor. De asemenea, e de mare ajutor pentru noi să completați formularul de rating pe care îl primiți pe mail la finalul fiecărei comenzi.",
			}
		]
	}
}

export const FAQPage = (props) => {
	const defaultActiveKey = getItemFromUrl(props.location.search, 'section', null) ?? "order";


	return (
		<GuestTemplate label="faq" title="Intrebari"
					   subtitle="Am pregatit o lista cu intrebarile frecvente adresate de utilizatori"
					   image={null}
		>
			<Container className="faq-container mt-5">
				<Row>
					<Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey}>
						<Row>
							<Col md={3}>
								<Nav variant="pills" className="nav-titles">
									{
										Object.keys(texts).map((key) => {
											const value = texts[key]
											return (
												<Nav.Item key={key}>
													<Nav.Link eventKey={key}>
														<Icon icon={value.icon} width={24} className="nav-link-icon"/>
														{value.title}
													</Nav.Link>
												</Nav.Item>
											)
										})
									}

								</Nav>
							</Col>
							<Col md={9}>
								<Tab.Content>
									{
										Object.keys(texts).map((key) => {
											const value = texts[key]
											return (
												<Tab.Pane eventKey={key} key={key}>
													{
														value.sections.map((section, index) => {
															return (
																<div className="section-content" key={index}>
																	<h5 className="section-title">
																		{section.title}
																	</h5>
																	<p className="section-desc">
																		{section.desc}
																	</p>
																</div>
															)
														})
													}

												</Tab.Pane>
											)
										})
									}

								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Row>

			</Container>

		</GuestTemplate>
	)
}
export default FAQPage