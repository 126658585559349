import React from "react"
import {translate} from "../i18n";
import {getProductOptionName} from "./productOption";

export const getPricesPerDurationFromProduct = (product) => {
    const prices = []

    const options = product.productOptions
    options.sort((a, b) => {
        return a?.publicPrice?.value - b?.publicPrice?.value
    })
    options.forEach((option) => {
        if (option?.productOptionTime?.durations && option.enabled && option.publicPrice) {
            prices.push({
                ...option.publicPrice,
                duration: option.productOptionTime.durations[0],
                name: getProductOptionName(option)
            })
        }
    })
    if (prices.length > 2) {
        return [prices[0], prices[prices.length - 1]]
    }
    return prices
}

export const getProductName = (product) => {
    if (product.title) {
        return product.title
    }
    return translate('product.' + product.code)
}

export const calculatePriceByOptions = (product, productOptions) => {
    if (!productOptions?.length) {
        return product.price;
    }
    let price = 0;
    productOptions.forEach((option) => {
        price += (product.price * option.priceMultiplier + option.priceAddition)
    })
    return price
}