import React, {useEffect, useState} from 'react';
import GuestTemplate from "../GuestTemplate";
import {Col, Container, Row} from "react-bootstrap";
import {colors} from "../../../utils/style/style";

import "../../../assets/css/guest/homeServices/homeServices.scss"
import {Link} from "react-router-dom";
import {
	ROUTE_ORDER,
	ROUTE_ORDER_FAST_PRODUCT_FORM,
	ROUTE_ORDER_STEP,
	ROUTE_PARTNER_APP,
	ROUTE_SERVICES
} from "../../../routes/constants";
import fair_prices from "../../../assets/images/new/homeServices/zumzi_preturi_mici.png"
import more_time from "../../../assets/images/new/homeServices/zumzi_mai_mult_timp.png"
import safety from "../../../assets/images/new/homeServices/zumzI_sigur.png"
import zumzi_phone from "../../../assets/images/new/homeServices/zumzi_telefon_aplicatie.png";
import mihai_ionita from "../../../assets/images/new/homeServices/zumzi_mihai_ionita.png";
import ana_canja from "../../../assets/images/new/homeServices/zumzi_ana_canja.png";
import anca_draghia from "../../../assets/images/new/homeServices/zumzi_anca_draghia.png";
import calin_fusu from "../../../assets/images/new/homeServices/zumzi_calin_fusu.png";
import zumzi_friend1 from "../../../assets/images/new/homeServices/zumzi_prietenul_casei_tale.png"
import zumzi_friend2 from "../../../assets/images/new/homeServices/zumzi_agent_servicii.png"
import zumzi_partner from "../../../assets/images/new/homeServices/zumzi_partner.png"

import DownloadLinks from "../../common/downloadApp/DownloadLinks";
import zumzi_phone_mobile from "../../../assets/images/new/homeServices/descarca_aplicatia_zumzi_telefon.png";
import {
	PRODUCT_ID_CARPET_CLEANING,
	PRODUCT_ID_CLEAN_COUCH,
	PRODUCT_ID_ELECTRICIAN,
	PRODUCT_ID_MANAGEMENT_SUBSCRIPTION,
	PRODUCT_ID_MASSAGE,
	PRODUCT_ID_ONE_TIME_CLEANING,
	PRODUCT_ID_PLUMBER,
	TOP_ONE_TIME_PRODUCT_CODES
} from "../../../utils/constants/product";
import zumzi_subscription from "../../../assets/images/new/services/big_icons/home_management_subscription.png"
import clean_one_time from "../../../assets/images/new/services/big_icons/clean_one_time.png"
import {getDownloadAppLink} from "../../../utils/constants/app";
import {Icon} from "@iconify/react/dist/iconify";
import {useDispatch, useSelector} from "react-redux";
import {getProductsAction, showModalPopup} from "../../../redux/actions";
import ServicesList from "../services/ServicesList";
import ServicePrice from "../services/ServicePrice";
import SliderComponent from "../../common/SliderComponent";
import ServiceItemColumn from "../services/ServiceItemColumn";
import {useWindowResize} from "../../../utils/hooks/useWindowResize";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";

const benefits = [
	{
		img: more_time,
		title: "Mai mult timp pentru tine",
		desc: "Sa apelezi la profesionisti pentru servicii in curatenie, sisteme electrice sau instalatii inseamna sa-ti acorzi tie mai mult timp pentru ceea ce iti place.",
		alt: "mai mult timp",
	},
	{
		img: fair_prices,
		title: "Cost mai mic",
		desc: "Fie ca apelezi la o echipa de curatenie, un instalator, un expert in masaj sau un electrician, avem grija ca preturile sa fie mereu corecte.",
		alt: "zumzi preturi",
	},
	{
		img: safety,
		title: "Mai usor si mai sigur",
		desc: "Interactionezi doar cu profesionisti verificati de catre echipa noastra, aflati in proximitatea casei tale.",
		alt: "zumzi siguranta",
	},
]

const howItWorksSteps = [
	{
		title: 'Parteneri verificati',
		desc: 'Verificam fiecare potential partener inainte, pentru a fi siguri ca pastreaza aceleasi stadarde inalte pe care ni le dorim in echipa Zumzi. Cei care iti vor trece pragul sunt doar profesionisti.',
	},
	{
		title: 'Parteneri dedicati',
		desc: 'Daca ai apelat deja la un serviciu Zumzi si colaborarea a avut succes, partenerul Zumzi va ramane un profesionist dedicat proiectelor tale.',
	},
	{
		title: 'Notificari in timp real',
		desc: 'Fie ca urmaresti din aplicatie sau direct din platforma, noi te vom anunta in timp real despre fiecare schimbare a comenzii tale.',
	},
	{
		title: 'Aproape de tine',
		desc: 'Cautam cei mai potriviti parteneri aflati in proximitatea ta, pentru a ne asigura ca ei sunt acolo oricand ai nevoie.',
	},
]

const testimonials = [
	{
		img: mihai_ionita,
		title: 'Mihai Ionita',
		subtitle: 'Avocat PBNI',
		desc: 'O aplicație esențială pentru un om cu abordare practică a timpului său liber!',
	},
	{
		img: anca_draghia,
		title: 'Anca Draghia',
		subtitle: 'Consultant Comunicare',
		desc: 'E uimitor câte lucruri poți face în timpul liber pe care inainte il foloseai pentru activitati casnice. Recomand Zumzi.',
	},
	{
		img: ana_canja,
		title: 'Ana Canja',
		subtitle: 'Freelancer',
		desc: 'Cel mai bun serviciu de homeServices management din oraș! Poți să comanzi online și în fiecare săptămână  cineva îți va curăța casa.',
	},
	{
		img: calin_fusu,
		title: 'Calin Fusu',
		subtitle: 'CEO Neogen Capital',
		desc: 'Am fost atât de impresionat de servicii și de platforma încât am și investit în companie',
	},
]

const newsPresence = [
	{
		url: 'https://www.zf.ro/companii/calin-fusu-investeste-150-000-euro-in-cleany-ro-platforma-de-servicii-de-curatenielansata-de-2-studenti-la-electronica-18263509?utm_source=zumzi.com',
		imgUrl: 'https://www.zf.ro/images/Logo%20+%20Slogan.png',
		alt: 'ziarul financiar',
	},
	{
		url: 'https://www.businessmagazin.ro/actualitate/casta-ro-isi-continua-investitiile-si-se-extinde-pe-piata-serviciilor-de-curatenie-18263542?utm_source=zumzi.com',
		imgUrl: 'https://www.businessmagazin.ro/images/logo.png',
		alt: 'businessmagazin',
	},
	{
		url: 'https://www.economica.net/casta-ro-investeste-150-000-de-euro-in-compania-de-curatenie-cleany-si-lanseaza-casta-clean_172248.html?utm_source=zumzi.com',
		imgUrl: 'https://www.economica.net/wp-content/themes/economica-twenty/assets/images/logo.svg',
		alt: 'economica',
	},
	{
		url: 'https://www.profit.ro/povesti-cu-profit/serviciul-de-curateniecleany-ro-lansat-de-doi-studenti-din-dorinta-de-a-scapa-de-gura-mamei-atrage-150-000-euro-de-la-portalul-imobiliar-casta-19078843?utm_source=zumzi.com',
		imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Logo_Profit.ro_%282018%29.svg/2560px-Logo_Profit.ro_%282018%29.svg.png',
		alt: 'profit',
	},
	{
		url: 'https://www.facebook.com/biziliveTV/videos/381639232410128/?utm_source=zumzi.com',
		imgUrl: 'https://bizilive.tv/wp-content/uploads/2022/01/logo-bizilive_3000X3000-1.png',
		alt: 'bizilive',
	},
	{
		url: 'https://www.startupcafe.ro/idei-si-antreprenori/menajere-servicii-curatenie-uber-cleany.htm?utm_source=zumzi.com',
		imgUrl: 'https://www.startupcafe.ro/themes/custom/startupcafetheme/images/logo.png',
		alt: 'startupcafe',
	},
	{
		url: 'https://www.stirilekanald.ro/tv?sid=aTgsdHhpLDA%3D&fbclid=IwAR2pdqWQVTdyy_3Gf-hDPZ8UTbtI0H_g-KOCrXNx9p_4SNSUhqQrNY2rQRE',
		imgUrl: 'https://www.stirilekanald.ro/img/stirilekanald/nav-logo.png',
		alt: 'kanal d',
	}
]

const mostSearchedServices1 = [
	{
		title: 'firma curatenie',
		url: ROUTE_SERVICES,
	},
	{
		title: 'firma de curatenie',
		url: ROUTE_SERVICES,
	},
	{
		title: 'curatenie bucuresti',
		url: ROUTE_SERVICES,
	},
	{
		title: 'produse curatenie ',
		url: ROUTE_SERVICES,
	},
]

const mostSearchedServices2 = [
	{
		title: 'curatenie la domiciliu ',
		url: ROUTE_SERVICES,
	},
	{
		title: 'masaj domiciliu',
		url: ROUTE_ORDER_FAST_PRODUCT_FORM + "?productId=" + PRODUCT_ID_MASSAGE,
	},
	{
		title: 'curatenie canapele bucuresti',
		url: ROUTE_ORDER_FAST_PRODUCT_FORM + "?productId=" + PRODUCT_ID_CLEAN_COUCH,
	},
	{
		title: 'curatenie covoare',
		url: ROUTE_ORDER_FAST_PRODUCT_FORM + "?productId=" + PRODUCT_ID_CARPET_CLEANING,
	},
]

const mostSearchedServices3 = [
	{
		title: 'servicii curatenie profesionala',
		url: ROUTE_SERVICES,
	},
	{
		title: 'curatat mochete',
		url: ROUTE_ORDER_FAST_PRODUCT_FORM + "?productId=" + PRODUCT_ID_CARPET_CLEANING,
	},
	{
		title: 'instalator bucuresti',
		url: ROUTE_ORDER_FAST_PRODUCT_FORM + "?productId=" + PRODUCT_ID_PLUMBER,
	},
	{
		title: 'electrician bucuresti',
		url: ROUTE_ORDER_FAST_PRODUCT_FORM + "?productId=" + PRODUCT_ID_ELECTRICIAN,
	},
]

const partnerBenefits = [
	"Castigi bani transparent",
	"Volum mai mare de clienti",
	"Lucrezi cat vrei, dupa propriul program",
	"Castigi bonusuri pe baza aprecierilor clientilor",
	"Alegi comenzile potrivite pentru tine",
	"Primesti recomandari din partea clientilor",
	"Primesti comenzi din zona ta",
	"Poti veni cu propriul serviciu pe care il vom integra in platforma",
]

const TestimonialItem = ({testimonial}) => {
	return <div className="testimonials-container">
		<div className="t-header-container">
			<img src={testimonial.img} alt="zumzi user"/>
			<div>
				<p>
					{testimonial.title}
				</p>
				<p>
					{testimonial.subtitle}
				</p>
			</div>
		</div>
		<div className="t-desc-container">
			<p>
				{testimonial.desc}
			</p>
		</div>
	</div>
}

export const HomeServicesPage = ({location}) => {


	const {width} = useWindowResize()
	const products = useSelector(state => state.products)
	const dispatch = useDispatch()

	const [homeManagementProduct, setHomeManagementProduct] = useState(null)
	const [oneTimeCleaningProduct, setOneTimeCleaningProduct] = useState(null)
	const [topProducts, setTopProducts] = useState([])

	useEffect(() => {
		if (getItemFromUrl(location.search, 'utm_source', null) === 'cleany') {
			dispatch(showModalPopup("Cleany s-a mutat pe Zumzi", <><h5 className="account-title">
				Aici avem serviciile de curatenie din Cleany, plus multe alte servicii pentru casa ta.
			</h5></>))
		}
		dispatch(getProductsAction(false))
	}, [])

	useEffect(() => {
		if (products?.length) {
			setHomeManagementProduct(products.filter((item) => {
				return item.id === PRODUCT_ID_MANAGEMENT_SUBSCRIPTION
			})[0])
			setOneTimeCleaningProduct(products.filter((item) => {
				return item.id === PRODUCT_ID_ONE_TIME_CLEANING
			})[0])
			setTopProducts(products.filter((item) => {
				return TOP_ONE_TIME_PRODUCT_CODES.includes(item.code)
			}))
		}
	}, [products])

	return (
		<GuestTemplate
			label="home_services"
			title={<>Grijă pentru <br className="br-phone"/> casa ta</>}
			subtitle="Servicii pentru tine si casa ta oferite de profesionisti"
			cta={true}
			moreDetails={true}
		>

			{/* prezentare abonament */}
			<Container className="subscription-home-container">
				<Row>
					<Col lg={6} className="subscription-home-container-left">
						<img src={zumzi_friend1} alt="abonamnet zumzi plus"/>
						<h1 className="font-weight-bold">
							Zumzi Plus - Mai multe servicii într-un singur loc
						</h1>
						<p className="color-grey-text">
							Task-urile sunt gata cat ai zice Zumzi. Abonamentul lunar Zumzi Plus iti aduce in fiecare
							saptamana un agent dedicat Zumzi ce va petrece cateva ore pentru a fi sigur ca tot ceea ce
							aveai trecut pe lista este rezolvat. De la clasicul gunoi dus, pana la curatenia in casa sau
							organizarea rafturilor, toate acestea sunt atributii pe care agentul Zumzi le va indeplini
							pentru tine.
						</p>
						<div className="d-none d-lg-block ">
							<Link to={`${ROUTE_ORDER}?productId=${PRODUCT_ID_MANAGEMENT_SUBSCRIPTION}`}
								  className="button-cta-sec p-font-size mb-4">
								Aboneaza-te la Zumzi Plus
							</Link>
						</div>

					</Col>
					<Col lg={6} className="subscription-home-container-right">
						<div className="d-block d-lg-none text-center">
							<img src={zumzi_friend2} alt="servicii zumzi"/>
						</div>
						<h1 className="font-weight-bold">
							Agentul Zumzi -<br/>Prietenul casei tale
						</h1>
						<p className="color-grey-text">
							Agentul Zumzi este aici pentru tine si pentru a te ajuta sa stergi de pe lista task-urile
							saptamanale. Vei avea un partener dedicat, cu care sa creezi o relatie de incredere si care
							sa cunoasca nevoile tale, dar si pe cele ale casei. Viata devine mult mai simpla atunci cand
							ai un ajutor in fiecare saptamana.
						</p>
						<div className="d-none d-lg-block text-center">
							<img src={zumzi_friend2} alt="servicii zumzi"/>
						</div>

						<div className="d-block d-lg-none text-center mt-5">
							<Link to={`${ROUTE_ORDER}?productId=${PRODUCT_ID_MANAGEMENT_SUBSCRIPTION}`}
								  className="button-cta-sec p-font-size mb-4">
								Aboneaza-te la Zumzi Plus
							</Link>
						</div>
					</Col>
				</Row>
			</Container>

			{/* benefits*/}
			<Container className="home-benefits-container" style={{marginBottom: '3rem'}}>
				<Row className="mt-6">
					{
						benefits.map((benefit, index) => {
							return <Col lg={4} className="text-center mb-5" key={index}>
								<img src={benefit.img} alt={benefit.alt} style={{width: '100%'}}/>
								<h4>
									{benefit.title}
								</h4>
								<p className="color-grey-text" style={{padding: '0 2rem'}}>
									{benefit.desc}
								</p>
							</Col>
						})
					}
				</Row>
			</Container>

			{/* how it works*/}
			<div className="how-it-works-container">
				<Container>
					<Row>
						<Col lg={4} className="d-block d-lg-none img-container">
							<div>
								<h1 className="font-weight-bold">
									Pe mana profesionistilor
								</h1>
							</div>
							<img src={zumzi_phone} alt="zumzi telefon"/>
						</Col>
						<Col lg={8} className="content-container">
							<div className="title-container">
								<h1 className="font-weight-bold">
									Pe mana profesionistilor
								</h1>
							</div>
							<div className="step-container">
								{
									howItWorksSteps.map((step, index) => {
										return <div className={"step " + (index % 2 === 0 ? "step-even" : "")}
													key={index}>
											<div>
												<h4 className="font-weight-bold">
													{step.title}
												</h4>
												<p>
													{step.desc}
												</p>
											</div>
										</div>
									})
								}
								<div className="step-cta-container">
									<p>
										Viata este mult mai frumoasa atunci cand ai timp sa te ocupi de tine.
										<br/>
										Lasa specialistii sa rezolve micile probleme pentru tine.
									</p>

									<a href={getDownloadAppLink()} target="_blank"
									   className="button-cta-sec p-font-size">
										Descarca aplicatia
									</a>
								</div>
							</div>
						</Col>
						<Col lg={4} className="d-none d-lg-block img-container">
							<div>
								<h1 className="font-weight-bold">
									Pe mana profesionistilor
								</h1>
							</div>
							<img src={zumzi_phone} alt="zumzi telefon"/>
						</Col>

					</Row>
				</Container>
			</div>

			{/* main services */}
			<Container className="service-top-container">
				<Row>
					<Col lg={4} className="service-top-container-left mb-4">
						<h1 className="font-weight-bold">
							Viata este mai simpla cu un ajutor constant
						</h1>
					</Col>
					<Col lg={4} className="mb-4">
						<div className="service-top-container-item">
							<img src={zumzi_subscription} alt="abonament zumzi plus"/>
							<h4 className="font-weight-bold">
								Zumzi Plus
							</h4>
							<span className="font-weight-bold p-font-size">
								{
									homeManagementProduct
										? <ServicePrice product={homeManagementProduct}/>
										: null
								}
							</span>
							<p className="color-grey-text">
								Ajutorul tau in casa pentru mici task-uri vine sub forma unui abonament lunar. In
								fiecare saptamana, un partener dedicat Zumzi va bifa pentru tine toate activitatile de
								care nu ai avut timp sa te ocupi.
							</p>
							<Link to={`${ROUTE_ORDER}?productId=${PRODUCT_ID_MANAGEMENT_SUBSCRIPTION}`}
								  className="button-cta-sec p-font-size mb-4">
								Aboneaza-te la Zumzi Plus
							</Link>
						</div>
					</Col>

					<Col lg={4} className="mb-4">
						<div className="service-top-container-item">
							<img src={clean_one_time} alt="abonament zumzi plus"/>
							<h4 className="font-weight-bold">
								O curatenie
							</h4>

							{
								oneTimeCleaningProduct
									?
									<ServicePrice product={oneTimeCleaningProduct}/>
									: null
							}

							<p className="color-grey-text">
								Apeleaza la acest serviciu daca ai nevoie de o curatenie in casa, realizata de un
								partener dedicat, pe o durata de timp diferita.
							</p>
							<br/>

							<Link to={`${ROUTE_ORDER}?productId=${PRODUCT_ID_ONE_TIME_CLEANING}`}
								  className="button-gradient-primary p-font-size mb-4">
								Comanda curatenie
							</Link>
						</div>
					</Col>
				</Row>
			</Container>

			{/* Servicii one-time pentru tine si casa ta. */}
			<Container className="mb-6">
				<Row>
					<Col lg={12}>
						<h1 className="font-weight-bold mb-5">
							Servicii one-time pentru tine si casa ta.
						</h1>
					</Col>
				</Row>

				<Row className="d-none d-lg-flex">
					{
						topProducts.length
							? <>
								<ServicesList
									type="column"
									categories={[
										{
											products: topProducts,
											subcategories: [],
										}
									]}
								/>
								<div className="mb-5 text-center">
									<Link to={ROUTE_SERVICES} className="p-font-size text-decoration-underline">
										Vezi toate serviciile
									</Link>
								</div>

							</>
							: null
					}
				</Row>
				<Row className="d-flex d-lg-none">
					{
						products?.length
							? <SliderComponent
								items={products.filter((item) => {
									return !item.fixedPrice
								})}
								ItemComponent={({item}) => {
									return <ServiceItemColumn
										service={{data: item, type: 'product'}}
									/>
								}}
								extraClassName="slick-slider-full"
							/>
							: null
					}
				</Row>
			</Container>

			{/* testimonials */}
			<Container className="mt-5 mb-5">
				<Row>
					<Col lg={3} className="mb-4">
						<h1 className="font-weight-bold" style={{paddingBottom: '2rem'}}>
							Ce spune
							<br/>
							comunitatea{" "}
							<br className="br-lg"/>
							Zumzi
						</h1>
						<p style={{paddingBottom: '2rem'}}>
							Cei care au apelat la serviciile Zumzi au grija atat de casa lor, cat si de timpul liber de
							care dispun. Daca si tu iti doresti sa ai parte de mai mult timp pentru hobby-urile tale si
							sa ii lasi pe profesionisti sa te ajute la curatenie in casa si nu numai, te asteptam in
							aplicatia Zumzi.
						</p>
						<Link to={`${ROUTE_ORDER_STEP}?productId=${PRODUCT_ID_MANAGEMENT_SUBSCRIPTION}`}
							  className="button-cta-sec p-font-size d-none d-lg-inline">
							Aboneaza-te la Zumzi Plus
						</Link>
					</Col>
					<Col lg={3} className="offset-lg-1">
						<TestimonialItem testimonial={testimonials[0]}/>
						<TestimonialItem testimonial={testimonials[1]}/>
					</Col>
					<Col lg={3} className="offset-lg-1">
						<TestimonialItem testimonial={testimonials[2]}/>
						<TestimonialItem testimonial={testimonials[3]}/>
					</Col>
					<Col lg={12} className="d-block d-lg-none text-center mb-4 mt-4">
						<Link to={`${ROUTE_ORDER_STEP}?productId=${PRODUCT_ID_MANAGEMENT_SUBSCRIPTION}`}
							  className="button-cta-sec p-font-size">
							Aboneaza-te la Zumzi Plus
						</Link>
					</Col>
				</Row>
			</Container>

			{/*	download app */}
			<Container fluid className="download-app-container">
				<Row>
					<Col lg={4} className="offset-lg-4 d-app-content text-center">
						<h1 className="font-weight-bold mb-3">
							Descarca aplicatia
						</h1>
						<p className="color-grey-text mb-5">
							Instaleaza aplicatia Zumzi pentru un control sporit, recenzii ale comunitatii si notificari
							frecvente ale comenzilor tale active.
						</p>
						<div className="d-download-links">
							<DownloadLinks/>
						</div>
					</Col>
				</Row>
				<Row>
					<div className="d-phone-mobile">
						<img src={zumzi_phone_mobile} alt="zumzi telefon"/>
					</div>
				</Row>
			</Container>

			{/* online news */}
			<Container className="mt-6 mb-6">
				<Row>
					<Col md={12} className="text-center">
						<h1 className="font-weight-bold mb-3">
							Prezenti pe
						</h1>
						<SliderComponent
							autoplay={true}
							slidesToShow={width > 1260 ? 5 : (width > 767 ? 3 : 1)}
							items={newsPresence}
							ItemComponent={({item}) => {
								return <div>
									<a
										href={item.url}
										target="_blank">
										<img src={item.imgUrl}
											 style={{maxWidth: '200px', maxHeight: '200px', margin: 'auto'}}/>
									</a>
								</div>
							}}
						/>
					</Col>
				</Row>
			</Container>


			{/* servicii frecvente */}
			<Container className="home-frequent-services-container mb-6 mt-6">
				<Row>
					<Col lg={12}>
						<h1 className="font-weight-bold mb-3 text-center mt-6 mb-5">
							Cele mai cautate servicii
						</h1>
					</Col>
					<Col lg={4}>
						{
							mostSearchedServices1.map((item, index) => {
								return <Link to={item.url} key={index} className="d-block p-font-size">
									{item.title}
								</Link>
							})
						}
					</Col>
					<Col lg={4}>
						{
							mostSearchedServices2.map((item, index) => {
								return <Link to={item.url} key={index} className="d-block p-font-size">
									{item.title}
								</Link>
							})
						}
					</Col>
					<Col lg={4}>
						{
							mostSearchedServices3.map((item, index) => {
								return <Link to={item.url} key={index} className="d-block p-font-size">
									{item.title}
								</Link>
							})
						}
					</Col>
				</Row>
			</Container>

			{/* partener */}
			<div className="home-become-partner">
				<Container>
					<Row>
						<Col lg={4} className="home-become-partner-left">
							<img src={zumzi_partner} alt="devino partener zumzi"/>
						</Col>
						<Col lg={8}>
							<h1 className="font-weight-bold">
								Alatura-te echipei Zumzi
							</h1>
							<p className="color-grey-text mb-5 p-font-size">
								Cei care au apelat la serviciile Zumzi au grija atat de casa lor, cat si de timpul liber
								de care dispun.
							</p>
							<Row className="home-become-partner-right">
								<Col lg={6} className="home-become-partner-right-first">
									{
										partnerBenefits.filter((item, index) => {
											return index < partnerBenefits.length / 2
										}).map((item, index) => {
											return <div key={index} className="home-benefit-item">
												<div>
													<Icon icon="akar-icons:check" width={28} color={colors.green}/>
												</div>
												<div>
													<span className="p-font-size color-grey-text">
														{item}
													</span>
												</div>

											</div>
										})
									}
								</Col>
								<Col lg={6}>
									{
										partnerBenefits.filter((item, index) => {
											return index >= partnerBenefits.length / 2
										}).map((item, index) => {
											return <div key={index} className="home-benefit-item">
												<div>
													<Icon icon="akar-icons:check" width={28} color={colors.green}/>
												</div>
												<div>
													<span className="p-font-size color-grey-text">
														{item}
													</span>
												</div>
											</div>
										})
									}
								</Col>
							</Row>
							<div className="mt-5 text-center-n-lg">
								<a href={ROUTE_PARTNER_APP} className="button-cta-sec p-font-size">
									Devino partener
								</a>
							</div>

						</Col>
					</Row>
				</Container>
			</div>

		</GuestTemplate>
	)
}
export const styles = {
	title: {},
	list: {
		marginTop: '1rem',
		paddingLeft: '1.5rem',
		color: colors.white,
		fontSize: '1.25rem',
	},
	serviceTitle: {
		color: colors.white,
		marginTop: '5rem',
		marginBottom: '3rem',
	},
}
export default HomeServicesPage
