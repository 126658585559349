import React, {useEffect, useState} from 'react'

import {translate} from "../../../utils/i18n";
import {Link} from "react-router-dom";
import {ROUTE_ORDER, ROUTE_ORDER_FORM, ROUTE_ORDER_STEP} from "../../../routes/constants";
import {CATEGORY} from "../../../utils/constants/constants";

import "../../../assets/css/guest/services/service_row.scss"
import {PRODUCT_CODE_HOME_MANAGEMENT_SUBSCRIPTION} from "../../../utils/constants/product";
import {useSelector} from "react-redux";

export const ServiceItemRow = ({service}) => {
	const [image, setImage] = useState(null)
	const userNeighbourhood = useSelector(state => state.userNeighbourhood)


	useEffect(() => {
		try {
			const img = service?.data.code ? require(`../../../assets/images/new/services/order/${service?.data.code}.png`) : null
			if (img) {
				setImage(img)
			}
		} catch (e){}

	})
	const setLink = () => {
		if (!userNeighbourhood?.userPublicAddress?.city?.availableForServices) {
			return '#'
		}

		if (service.type === CATEGORY) {

			if (PRODUCT_CODE_HOME_MANAGEMENT_SUBSCRIPTION === service.data.code) {
				return `${ROUTE_ORDER_STEP}?productId=${service.data.onlyProductId}`
			}

			if (service.data.onlyProductId) {
				return `${ROUTE_ORDER_FORM}?productId=${service.data.onlyProductId}`
			} else {
				return `${ROUTE_ORDER}?categoryId=${service.data.id}`
			}
		}
		if (service.data.fixedPrice) {
			return `${ROUTE_ORDER_STEP}?productId=${service.data.id}`
		}
		return `${ROUTE_ORDER_FORM}?productId=${service.data.id}`
	}

	if (!image) {
		return null
	}

	return (
		<Link
			className={"service-row-container" + (!userNeighbourhood?.userPublicAddress?.city?.availableForServices ? " disabled" : "")}
			to={setLink()}>
			<div className="service-image-container">
				<img alt={translate(service.type + '.' + service.data.code)} src={image}/>
			</div>

			<div className="service-text">
				<h4>
					{translate(service.type + '.' + service.data.code)}
				</h4>
				<p>
					{translate('product.' + service.data.code + '.' + 'desc')}
				</p>
			</div>
		</Link>
	)
}
export default ServiceItemRow