import React from 'react'
import {Icon} from "@iconify/react/dist/iconify";

export const IconsNav = (props) => {
    const color = props.color

    return <>
        <a href="tel:+40723146609" style={styles.icon}>
            <Icon icon="akar-icons:phone" color={color} width={20} height={20}/>
        </a>
        <a href="https://www.facebook.com/zumzi" style={styles.icon}>
            <Icon icon="brandico:facebook" color={color} width={20} height={20}/>
        </a>
        <a href="https://www.instagram.com/zumzi.app" style={styles.icon}>
            <Icon icon="ant-design:instagram-outlined" color={color} width={20} height={20}/>
        </a>
        <a href="mailto:hello@zumzi.com" style={styles.icon}>
            <Icon icon="ant-design:mail-outlined" color={color} width={20} height={20}/>
        </a>
    </>
}

const styles = {
    icon: {
        marginLeft: '0.5rem',
    },
}
export default IconsNav