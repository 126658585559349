import React from "react";
import Slider from "react-slick";

import "../../assets/css/components/slider/slider.scss"


export const SliderComponent = ({autoplay = false, extraClassName, slidesToShow = 1.2, items, ItemComponent, hideNavigation = false, centerMode = true}) => {
	if (!items?.length) {
		return null
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: slidesToShow,
		slidesToScroll: 1,
		autoplay: autoplay,
		autoplaySpeed: 3000,
		centerMode: centerMode,
		centerPadding: 0,
		swipe: true,
		swipeToSlide: true,
		className: (extraClassName ?? "") + (hideNavigation ? " slick-slider-hide-navigation" : ""),
	}


	return (
		<Slider {...settings}>
			{
				items && items?.map((item, index) => {
					return <div key={index}>
						<ItemComponent
							item={item}
						/>
					</div>
				})
			}
		</Slider>

	)
}
export default SliderComponent;