export const ROUTE_PARTNER_APP = 'https://www.agent.zumzi.com/'

export const ROUTE_HOME = '/'
export const ROUTE_HOME_SERVICES = '/servicii-pentru-casa'
export const ROUTE_SERVICES = '/servicii'
export const ROUTE_SERVICE = '/serviciu'
export const ROUTE_ORDER_FAST_PRODUCT_FORM = '/formular-de-comanda'

export const ROUTE_FAQ = '/intrebari'
export const ROUTE_CONTACT = '/contact'
export const ROUTE_PARTNER = '/partener'

export const ROUTE_TERMS_AND_CONDITIONS = '/termeni-si-conditii'
export const ROUTE_PRIVACY = '/politica-de-confidentialitate'
export const ROUTE_COOKIES = '/politica-de-utilizare-cookie'

export const ROUTE_LOGOUT = '/logout'
export const ROUTE_LOGIN = '/autentificare'
export const ROUTE_REGISTER = '/inregistrare'
export const ROUTE_RESET_PASSWORD = '/resetare-parola'
export const ROUTE_RECOVER_PASSWORD = '/new-password'

export const ROUTE_MY_ACCOUNT = '/contul-meu'

export const ROUTE_ORDER = ROUTE_MY_ACCOUNT + '/comanda-serviciu'
export const ROUTE_ORDER_STEP = ROUTE_MY_ACCOUNT + '/comanda'
export const ROUTE_ORDER_FORM = ROUTE_MY_ACCOUNT + '/formular-de-comanda'
export const ROUTE_ORDER_FORM_REQUEST_STEP = ROUTE_MY_ACCOUNT + '/finalizeaza-comanda'
export const ROUTE_3DS_PAYMENT = '/complete-payment-3ds'

export const ROUTE_MY_NEIGHBOURHOOD = ROUTE_MY_ACCOUNT + '/cartierul-meu'
export const ROUTE_DASHBOARD = ROUTE_ORDER
export const ROUTE_PAYMENT_METHODS = ROUTE_MY_ACCOUNT + '/modalitati-de-plata'
export const ROUTE_VOUCHERS = ROUTE_MY_ACCOUNT + '/recompense'
export const ROUTE_PERSONAL_DATA = ROUTE_MY_ACCOUNT + '/date-personale'
export const ROUTE_PAYMENTS = ROUTE_MY_ACCOUNT + '/plati'
export const ROUTE_MY_ORDERS = ROUTE_MY_ACCOUNT + '/comenzile-mele'
export const ROUTE_BOOKING = ROUTE_MY_ACCOUNT + '/programare'
export const ROUTE_BOOKING_FEEDBACK = ROUTE_MY_ACCOUNT + '/programare/lasa-recenzie'
export const ROUTE_MY_PRODUCT_FORMS = ROUTE_MY_ACCOUNT + '/formularele-mele'
export const ROUTE_ACCOUNT_PRODUCT_FORM = ROUTE_MY_ACCOUNT + '/formular'

export const ROUTE_MY_SUBSCRIPTION = ROUTE_MY_ORDERS + '/abonament'
export const ROUTE_MY_BOOKING = ROUTE_MY_ORDERS + '/sedinta'
export const ROUTE_MY_PRODUCT_FORM = ROUTE_MY_ORDERS + '/formular-de-comanda'


export const ROUTE_ACCOUNT_CONTACT = ROUTE_MY_ACCOUNT + '/contact'

export const ROUTE_SELECT_COMPLEX = ROUTE_MY_ACCOUNT + '/selecteaza-adresa'
export const ROUTE_ADD_ADDRESS = ROUTE_MY_ACCOUNT + '/adauga-adresa'

