import React, {useState} from 'react';
import GuestTemplate from "../GuestTemplate";
import {Col, Container, Row} from "react-bootstrap";
import {colors} from "../../../utils/style/style";

import "../../../assets/css/guest/home/home.scss"

import maitenance from "../../../assets/images/new/home/maintenance.png"
import calendar from "../../../assets/images/new/home/calendar.png"
import residents from "../../../assets/images/new/home/residents.png"
import listing from "../../../assets/images/new/home/listing.png"
import benefitsImage from "../../../assets/images/new/home/zumzi_benefits.png"

import mihai_ionita from "../../../assets/images/new/homeServices/zumzi_mihai_ionita.png";
import ana_canja from "../../../assets/images/new/homeServices/zumzi_ana_canja.png";
import calin_fusu from "../../../assets/images/new/homeServices/zumzi_calin_fusu.png";
import {Link} from "react-router-dom";
import SliderComponent from "../../common/SliderComponent";
import {useInputValidation} from "../../../utils/hooks/useInputValidation";
import {isEmail, isMessage, isName, isPhone} from "../../../utils/validation/conditions";
import {translate} from "../../../utils/i18n";
import {isValidObject} from "../../../utils/validation/validate";
import {contactUnauthAction} from "../../../redux/actions";
import InputField from "../../common/input/InputField";
import {useDispatch} from "react-redux";
import CheckboxField from "../../common/input/CheckboxField";

const benefits = [
	{
		img: maitenance,
		title: "Mententanta completa asigurata",
		desc: "Sa apelezi la profesionisti pentru servicii in curatenie, sisteme electrice sau instalatii inseamna sa-ti acorzi tie mai mult timp pentru ceea ce iti place.",
		alt: "mentenanta",
	},
	{
		img: calendar,
		title: "Fara pauze intre chiriasi",
		desc: "Fie ca apelezi la o echipa de curatenie, un instalator, un expert in masaj sau un electrician, avem grija ca preturile sa fie mereu corecte.",
		alt: "fara pauze",
	},
	{
		img: residents,
		title: "Chiriasi verificati",
		desc: "Interactionezi doar cu profesionisti verificati de catre echipa noastra, aflati in proximitatea casei tale.",
		alt: "rezidenti",
	},
	{
		img: listing,
		title: "Listare pe mai multe platforme",
		desc: "Interactionezi doar cu profesionisti verificati de catre echipa noastra, aflati in proximitatea casei tale.",
		alt: "listare",
	},
]

const howItWorksSteps = [
	{
		title: 'Contacteaza-ne',
	},
	{
		title: 'Inscrieti proprietatea',
	},
	{
		title: 'Listam proprietatea pe diverse platforme',
	},
	{
		title: 'Oferim suport pentru chiriasii tai',
	},
	{
		title: 'Oferim mentenanta pentru proprietatea ta',
	},
]

const testimonials = [
	{
		img: calin_fusu,
		title: 'Calin Fusu',
		subtitle: 'CEO Neogen Capital',
		desc: 'Am fost atât de impresionat de servicii și de platforma încât am și investit în companie',
	},
	{
		img: mihai_ionita,
		title: 'Mihai Ionita',
		subtitle: 'Avocat PBNI',
		desc: 'E uimitor ca ai un randament mult mai bun decat in cazul inchirierii pe termen lung',
	},
	{
		img: ana_canja,
		title: 'Ana Canja',
		subtitle: 'Freelancer',
		desc: 'Cel mai bun serviciu de home management din oraș! Inchiriere fara batai de cap',
	},
]


export const HomePage = ({location}) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [message, setMessage] = useState('')
	const [acceptedTerms, setAcceptedTerms] = useState(false)

	const validations = {
		name: useInputValidation(name, isName(name), translate('validation.name.invalid')),
		email: useInputValidation(email, isEmail(email), translate('validation.email.invalid')),
		phone: useInputValidation(phone, isPhone(phone), translate('validation.phone.invalid')),
		message: useInputValidation(message, isMessage(message), translate('validation.message.invalid')),
		acceptedTerms: useInputValidation(acceptedTerms, acceptedTerms === true, translate('validation.terms.invalid'))
	}

	const dispatch = useDispatch()

	const onSubmitForm = async () => {
		if (isValidObject(validations)) {
			dispatch(contactUnauthAction({email, name, phone, message}))
		}
	}

	return (
		<GuestTemplate
			label="home"
			title={<>Partener cu Zumzi</>}
			subtitle="Zumzi oferta proprietarilor, dezvoltatorilor imobiliari si proprietarilor venituri sigure prin închiriere pe termen scurt."
			showHeroBanner={false}
		>
			<Container className="banner-container">
				<Row>
					<Col lg={8} className="d-none d-lg-block">
						<div  className="banner-img"/>
					</Col>
					<Col lg={6} className="banner-form">
						<div className="home-form">
							<h1 className="color-white">
								Partener cu Zumzi
							</h1>
							<p className="color-white mb-4">
								Zumzi oferta proprietarilor, dezvoltatorilor imobiliari si proprietarilor venituri sigure prin închiriere pe termen scurt.
							</p>

							<InputField
								type="text"
								placeholder="Nume si prenume"
								value={name}
								setValue={setName}
								validation={validations.name}
							/>
							<InputField
								type="email"
								placeholder="Adresa de email"
								value={email}
								setValue={setEmail}
								validation={validations.email}
							/>
							<InputField
								type="text"
								placeholder="Numar de telefon"
								value={phone}
								setValue={setPhone}
								validation={validations.phone}
							/>
							<InputField
								label=""
								type="text"
								isTextarea={true}
								rows={3}
								placeholder="Detalii despre locatiile tale: adresa, numar camere, stare etc."
								value={message}
								setValue={setMessage}
								validation={validations.message}
								onSubmitForm={onSubmitForm}
							/>
							<CheckboxField
								id="acceptedTerms"
								value={acceptedTerms}
								setValue={() => {
									setAcceptedTerms(!acceptedTerms)
								}}
								label={<span className="color-white">Accept termenii si conditiile</span>}
								validation={validations.acceptedTerms}
							/>
							<button onClick={onSubmitForm} className="button-cta-sec mt-3 p-font-size d-block w-100">
								Inscrieti proprietatea
							</button>
						</div>
					</Col>
				</Row>
			</Container>

			{/* benefits*/}
			<Container style={{marginTop: '5rem', marginBottom: '3rem'}}>
				<Row className="mt-6">
					<Col lg={8}>
						<div>
							<h1 className="font-weight-bold text-center-n-lg">
								Beneficiile noastre
							</h1>
							<p className="text-center-n-lg" style={{fontWeight: 500}}>
								De ce proprietarii si dezvoltatorii imobiliari <br/>aleg Zumzi
							</p>
						</div>
						<Row style={{marginTop: '3rem'}}>
							{
								benefits.map((benefit, index) => {
									return <Col lg={6} className="mb-5" key={index}>
										<img src={benefit.img} alt={benefit.alt}/>
										<h4 className="font-weight-bold" style={{marginTop: '2rem', marginBottom: '1rem'}}>
											{benefit.title}
										</h4>
										<p className="color-grey-text">
											{benefit.desc}
										</p>
									</Col>
								})
							}
						</Row>
					</Col>

					<Col lg={4} style={{textAlign: 'center'}}>
						<img src={benefitsImage} alt="beneficii" className="benefits-image"/>
					</Col>
				</Row>
			</Container>

			{/* how it works */}
			<Container fluid style={{marginTop: '5rem', marginBottom: '3rem'}}>
				<Row>
					<div>
						<h1 className="font-weight-bold text-center">
							Cum functioneaza?
						</h1>
						<p className="text-center" style={{fontWeight: 500}}>
							De ce proprietarii si dezvoltatorii imobiliari aleg Zumzi
						</p>
					</div>
				</Row>
				<Row className="mt-6">
					<Col lg={4} className="d-none d-lg-block how-it-works-left-img">
					</Col>
					<Col lg={5}>
						<div className="how-it-works-center">
							{
								howItWorksSteps.map((step, index) => {
									return <div key={index}>
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											flexWrap: ''
										}}>
											<div style={{
												background: "linear-gradient(270deg, #345AC6 0.18%, #2541A1 100.18%)",
												borderRadius: '100%',
												marginRight: '1rem',
												height: '4rem',
												alignItems: 'center',
												display: 'flex',
												justifyContent: 'center',
												color: 'white',
												fontSize: '1.75rem',
												padding: '1.5rem'
											}}>
												{index + 1}
											</div>
											<span className="color-grey-text" style={{
												textDecoration: index ? 'none' : 'underline',
												fontSize: '1.75rem',
												lineHeight: '2rem'
											}}>
												{step.title}
											</span>
										</div>
										{
											index + 1 !== howItWorksSteps.length
												? <div
													style={{
														borderLeft: '2px dashed #C4C4C4',
														height: '4rem',
														marginLeft: 'calc(2rem - 1px)'
													}}
												/>
												: <></>
										}
									</div>
								})
							}
						</div>

					</Col>

					<Col lg={3} className="d-none d-lg-block how-it-works-right-img">
					</Col>

					<Col lg={12} className="text-center mt-6 mb-6">
						<Link to={`#form`} className="button-cta-sec p-font-size button-big">
							Inscrieti proprietatea
						</Link>
					</Col>

				</Row>
			</Container>

			{/* testimonials */}
			<Container className="mt-6 mb-6">
				<Row>
					<Col lg={12} className="mb-4 text-center-n-lg">
						<h1 className="font-weight-bold">
							Ce spun partenerii nostri
						</h1>
					</Col>
					<Col lg={12}>

						<SliderComponent
							slidesToShow={1}
							items={testimonials}
							ItemComponent={({item}) => {
								return <div className="testimonials-container">
									<div>
										<h1 style={{fontWeight: 400, color: 'white', marginTop: 0, marginBottom: '2rem'}}>
											"{item.desc}"
										</h1>
									</div>
									<div className="t-header-container">
										<img src={item.img} alt="zumzi user"/>
										<div>
											<p style={{color: 'white', fontWeight: 'bold'}}>
												{item.title}
											</p>
											<p style={{color: 'white', fontWeight: 100}}>
												{item.subtitle}
											</p>
										</div>
									</div>
								</div>
							}}
							extraClassName="slick-slider-top-right-buttons"
						/>
					</Col>
				</Row>
			</Container>

			{/*	our locations */}
			<Container style={{marginTop: '5rem', marginBottom: '3rem'}}>
				<Row>
					<Col lg={12} className="d-block d-lg-none" style={{marginTop: '3rem'}}/>
					<Col lg={12}>
						<h1 className="font-weight-bold text-center-n-lg" style={{paddingBottom: '2rem'}}>
							Locatiile noastre
						</h1>
					</Col>
				</Row>
			</Container>
			<Container fluid>
				<Row className="locations-first-row">
					<Col lg={1} className="d-none d-lg-block locations-first-col-1">
						<div className="locations locations-first-1"/>
					</Col>
					<Col xs={5} lg={6} className="locations-first-col-2">
						<div className="locations locations-first-2"/>
					</Col>
					<Col xs={7} lg={5} className="locations-first-col-3">
						<div className="locations locations-first-3"/>
					</Col>
				</Row>

				<Row className="locations-second-row">
					<Col xs={1} lg={5} className="locations-second-col-1">
						<div className="locations locations-second-1"/>
					</Col>
					<Col xs={10} lg={6} className="locations-second-col-2">
						<div className="locations locations-second-2"/>
					</Col>
					<Col xs={1} lg={1} className="locations-second-col-3">
						<div className="locations locations-second-3"/>
					</Col>
				</Row>
			</Container>
		</GuestTemplate>
	)
}
export const styles = {
	title: {},
	list: {
		marginTop: '1rem',
		paddingLeft: '1.5rem',
		color: colors.white,
		fontSize: '1.25rem',
	},
	serviceTitle: {
		color: colors.white,
		marginTop: '5rem',
		marginBottom: '3rem',
	},
}
export default HomePage
